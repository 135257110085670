import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import { product, apiGet } from '../../services/api'
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import Toolbar from '@/components/toolbar/toolbar';


export default {
    name: "Content",
    data() {
        return {
            product_list: null,
            chk3: false,
            form: {
                id: '',
                ref_news_id: '',
                name_th: '',
                name_en: '',
                image_banner: null,
                content: null,
                footer_video: null,
                footer_image: [
                    { image: '' }
                ],
                create_by: "art",
                update_by: "art",
                sort: null,
            },
            post_date: "",
            month_th: [
                { id: "01", name: "ม.ค.", name_en:"Jan"},
                { id: "02", name: "ก.พ.", name_en:"Feb"},
                { id: "03", name: "มี.ค.", name_en:"March"},
                { id: "04", name: "เม.ย.", name_en:"April"},
                { id: "05", name: "พ.ค.", name_en:"May"},
                { id: "06", name: "มิ.ย.", name_en:"June"},
                { id: "07", name: "ก.ค.", name_en:"July"},
                { id: "08", name: "ส.ค.", name_en:"Aug"},
                { id: "09", name: "ก.ย.", name_en:"Sept"},
                { id: "10", name: "ต.ค.", name_en:"Oct"},
                { id: "11", name: "พ.ย.", name_en:"Nov"},
                { id: "12", name: "ธ.ค.", name_en:"Dec"},
            ],
            list_news: [],
            baseFront: this.$baseFront
        }
    },
    methods: {
        openLink(url) {
            console.log(url);
            window.open(url, "_blank");
          },
        FormatDate(value) {
            let data = value.split("T")
            let create_date = data[0].split("-")
            let day = create_date[2];
            let month = this.month_th.find(i => i.id == create_date[1])[(this.$lang == 'th' ? 'name':'name_en')]
            let year = parseInt(create_date[0]) + (this.$lang == 'th' ? 543:null);
            let date = day + ' ' + month + ' ' + year;
            this.post_date = date;
        },
        async fetchnews() {
            await this.$axios.get("/api/show/news")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.list_news = response.data.data
                    }
                })
        },
        async fetchSearch(id) {
            await this.$axios.get(`/api/show/news_content/${id}`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.form = response.data.data[0];
                        this.form.footer_image = this.form.footer_image == "" ? [] : JSON.parse(this.form.footer_image)
                        // JSON convert string array to array object
                        this.post_date = this.list_news.find(i=>i.id == this.form.ref_news_id).post_date
                        let post_date = (this.post_date ? this.post_date : this.form.create_date)
                        console.log(post_date);
                        this.FormatDate(post_date)
                    }
                })
        },
    },
    async mounted() {
        window.scrollTo(0, 0);
        await this.fetchnews()
        await this.fetchSearch(this.$route.params.id)
        var swiper = new Swiper(".mySwiper", {
            loop: true,
            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: "auto",
            coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            },
            pagination: {
                el: ".swiper-pagination",
            },
        });
        document.title = "AGC - Content";
        AOS.init();
    },
    components: {
        Footer, Header, Breadcrumb,Toolbar
    }
};